<template>
  <div class="main" data-title="意向客户跟踪填报" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">意向客户跟踪</h2>
      </div>
    </div>
    <el-form
      :model="form"
      :rules="rules"
      class="form"
      label-position="top"
      label-width="120px"
      ref="form"
    >
      <el-card class="main-content register-box" shadow="hover">
        <div class="jue-card-header">
          <div class="jue-card-title">跟踪信息</div>
          <div class="jue-card-btns" v-if="$route.query.client_id">
            <el-button @click="loadLastTrack" type="text" v-if="!$route.query.trace_id">
              <i class="el-icon-refresh-right"></i> 载入上次跟踪信息
            </el-button>
            <el-button
              @click="view($route.query.client_id)"
              icon="el-icon-coffee"
              type="primary"
            >客户登记档案</el-button>
          </div>
        </div>

        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="客户姓名" prop="client_id">
              <!-- <el-select
                :disabled="true"
                @change="changeClient"
                filterable
                placeholder="请选择"
                v-model="form.client_id"
              >
                <el-option :key="key" :label="item" :value="key" v-for="(item, key) in trackList"></el-option>
              </el-select> -->
              <el-input :disabled="true" placeholder="请输入" suffix-icon="el-icon-edit" v-model="form.realname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="跟踪时间" prop="trace_date">
              <el-date-picker
                :picker-options="pickerOptions"
                @change="changeCustomerLevel"
                format="yyyy年MM月dd日"
                placeholder="选择日期时间"
                type="date"
                v-model="form.trace_date"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="预计购车日期">
              <el-date-picker
                placeholder="选择日期时间"
                type="date"
                v-model="form.expect_buy_date"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="车型媒体价" prop="media_amount">
              <el-input
                oninput="value=value.replace(/[^\d.]/g,'')"
                placeholder="请输入"
                v-model="form.media_amount"
              >
                <template slot="prepend">￥</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="优惠折扣">
              <el-input
                oninput="value=value.replace(/[^\d.]/g,'')"
                placeholder="请输入"
                v-model="form.discount_amount"
              >
                <template slot="prepend">￥</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="客户级别" prop="client_level">
              <el-select
                @change="changeCustomerLevel"
                placeholder="请选择"
                v-model="form.client_level"
              >
                <template v-for="(item, index) in $store.state.cfgList.customerLevel">
                  <el-option
                    :key="index"
                    :label="item.f1"
                    :value="item.sys_id"
                    v-if="item.status == 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="竞争品牌">
              <el-input placeholder="请输入" suffix-icon="el-icon-edit" v-model="form.contend_brand"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="车辆净价">
              <el-input
                :disabled="true"
                oninput="value=value.replace(/[^\d.]/g,'')"
                placeholder="计算中"
                v-model="form.net_amount"
              >
                <template slot="prepend">￥</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="二手车折价">
              <el-input
                oninput="value=value.replace(/[^\d.]/g,'')"
                placeholder="请输入"
                v-model="form.used_car_amount"
              >
                <template slot="prepend">￥</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="预计下次回访日期" prop="next_trace_date">
              <el-date-picker
                placeholder="选择日期时间"
                type="date"
                v-model="form.next_trace_date"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="竞争车型">
              <el-input placeholder="请输入" suffix-icon="el-icon-edit" v-model="form.contend_model"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="预估保费" prop="insurance_tax_amount">
              <el-input
                oninput="value=value.replace(/[^\d.]/g,'')"
                placeholder="请输入"
                v-model="form.insurance_tax_amount"
              >
                <template slot="prepend">￥</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="车辆购置税">
              <el-input
                oninput="value=value.replace(/[^\d.]/g,'')"
                placeholder="请输入"
                v-model="form.purchase_tax_amount"
              >
                <template slot="prepend">￥</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="邀约到店日期">
              <!-- <el-date-picker
                placeholder="选择日期时间"
                type="date"
                v-model="form.expect_pay_date"
                value-format="yyyy-MM-dd"
              ></el-date-picker>-->

              <el-date-picker
                @change="setIncomeDate"
                align="right"
                end-placeholder="结束日期"
                format="yyyy年MM月dd日"
                range-separator="-"
                start-placeholder="开始日期"
                type="daterange"
                unlink-panels
                v-model="form.invite_income_date_begin_end"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="贷款方式">
              <el-select placeholder="请选择" v-model="form.credit_type">
                <template v-for="(item, index) in $store.state.cfgList.loansType">
                  <el-option
                    :key="index"
                    :label="item.f1"
                    :value="item.sys_id"
                    v-if="item.status == 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="综合服务费" prop="service_tax_amount">
              <el-input
                oninput="value=value.replace(/[^\d.]/g,'')"
                placeholder="请输入"
                v-model="form.service_tax_amount"
              >
                <template slot="prepend">￥</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="购买精品金额">
              <el-input
                oninput="value=value.replace(/[^\d.]/g,'')"
                placeholder="请输入"
                v-model="form.buy_parts_amount"
              >
                <template slot="prepend">￥</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="跟踪类型" prop="trace_type">
              <el-select placeholder="请选择" v-model="form.trace_type">
                <template v-for="(item, index) in $store.state.cfgList.trackingType">
                  <el-option
                    :key="index"
                    :label="item.f1"
                    :value="item.sys_id"
                    v-if="item.status == 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="原销售顾问">
              <el-select
                :disabled="true"
                filterable
                placeholder="自动计算"
                v-model="form.old_seller_uid"
              >
                <template v-for="(item, key) in $store.state.userList">
                  <el-option :key="key" :label="item[0]" :value="parseInt(key)" v-if="item[1] == 2"></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="trace_status">
              <span slot="label">
                促进结果
                <el-tooltip effect="dark" placement="top">
                  <div slot="content">请先选择「客户级别」</div>
                  <i class="el-icon-question icon-help"></i>
                </el-tooltip>
              </span>

              <el-select placeholder="请选择" v-model="form.trace_status">
                <template v-for="(item, index) in resultList">
                  <el-option
                    :key="index"
                    :label="item.f1"
                    :value="item.sys_id"
                    v-if="item.status == 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="购买精品明细">
              <el-input
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="请输入内容"
                type="textarea"
                v-model="form.buy_parts_items"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="赠送精品明细">
              <el-input
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="请输入内容"
                type="textarea"
                v-model="form.donate_parts_items"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="预计总费用">
              <el-input
                oninput="value=value.replace(/[^\d.]/g,'')"
                placeholder="计算中"
                readonly
                v-model="form.tax_amount"
              >
                <template slot="prepend">￥</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <span slot="label">
                跟踪文件
                <el-tooltip effect="dark" placement="top">
                  <div slot="content">电话录音 或 微信聊天截图</div>
                  <i class="el-icon-question icon-help"></i>
                </el-tooltip>
              </span>
              <div class="audio-box">
                <el-upload
                  :before-upload="beforeUpload"
                  :http-request="upload"
                  :limit="3"
                  :show-file-list="false"
                  action="''"
                  class="audio-upload"
                  ref="upload"
                >
                  <el-button
                    icon="el-icon-upload2"
                    plain
                    size="small"
                    slot="trigger"
                    type="primary"
                  >上传文件</el-button>
                </el-upload>
                <div class="audio-list">
                  <div :key="index" class="item" v-for="(item, index) in form.call_audio_list">
                    <!-- <i class="el-icon-video-play"></i> -->
                    <span class="name">{{item.name}}</span>
                    <i @click="delAudio(index)" class="el-icon-close"></i>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="跟踪描述">
              <el-input :rows="3" placeholder="请输入" type="textarea" v-model="form.trace_memo"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="form.credit_type != ''">
          <el-col :span="7" class="ins-amount">
            <el-form-item label="贷款金额" size="small">
              <el-input
                oninput="value=value.replace(/[^\d.]/g,'')"
                placeholder="请输入"
                suffix-icon="el-icon-edit"
                v-model="form.tmp_credit_detail.loan"
              ></el-input>
            </el-form-item>
            <el-form-item label="首付金额" size="small">
              <el-input
                oninput="value=value.replace(/[^\d.]/g,'')"
                placeholder="请输入"
                suffix-icon="el-icon-edit"
                v-model="form.tmp_credit_detail.downPayment"
              ></el-input>
            </el-form-item>
            <el-form-item label="月供金额" size="small">
              <el-input
                oninput="value=value.replace(/[^\d.]/g,'')"
                placeholder="请输入"
                suffix-icon="el-icon-edit"
                v-model="form.tmp_credit_detail.monthlyPayment"
              ></el-input>
            </el-form-item>
            <el-form-item label="利息金额" size="small">
              <el-input
                oninput="value=value.replace(/[^\d.]/g,'')"
                placeholder="请输入"
                suffix-icon="el-icon-edit"
                v-model="form.tmp_credit_detail.interest"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="银行审核费" size="small">
              <el-input
                oninput="value=value.replace(/[^\d.]/g,'')"
                placeholder="请输入"
                suffix-icon="el-icon-edit"
                v-model="form.tmp_credit_detail.cost1"
              ></el-input>
            </el-form-item>
            <el-form-item label="贷款审核费" size="small">
              <el-input
                oninput="value=value.replace(/[^\d.]/g,'')"
                placeholder="请输入"
                suffix-icon="el-icon-edit"
                v-model="form.tmp_credit_detail.cost2"
              ></el-input>
            </el-form-item>
            <el-form-item label="车辆抵押费" size="small">
              <el-input
                oninput="value=value.replace(/[^\d.]/g,'')"
                placeholder="请输入"
                suffix-icon="el-icon-edit"
                v-model="form.tmp_credit_detail.cost3"
              ></el-input>
            </el-form-item> -->
          </el-col>
          <el-col :span="10">
            <el-row>
              <el-col :span="24">
                <div class="ins-items" style="padding-top: 30px">
                  <el-checkbox
                    false-label="2"
                    true-label="1"
                    v-model="form.tmp_insurance_detail.three"
                  >三责</el-checkbox>
                  <el-input
                    oninput="value=value.replace(/[^\d.]/g,'')"
                    placeholder="请输入内容"
                    v-model="form.tmp_insurance_detail.threePayment"
                  ></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row class="insurance">
              <el-col :span="12">
                <el-checkbox
                  false-label="2"
                  true-label="1"
                  v-model="form.tmp_insurance_detail.insurance1"
                >交强险</el-checkbox>
              </el-col>
              <el-col :span="12">
                <el-checkbox
                  false-label="2"
                  true-label="1"
                  v-model="form.tmp_insurance_detail.insurance2"
                >车损险</el-checkbox>
              </el-col>
              <!-- <el-col :span="12">
                <el-checkbox
                  false-label="2"
                  true-label="1"
                  v-model="form.tmp_insurance_detail.insurance3"
                >不计免赔</el-checkbox>
              </el-col> -->
              <el-col :span="12">
                <el-checkbox
                  false-label="2"
                  true-label="1"
                  v-model="form.tmp_insurance_detail.insurance4"
                >划痕险</el-checkbox>
              </el-col>
              <el-col :span="12">
                <el-checkbox
                  false-label="2"
                  true-label="1"
                  v-model="form.tmp_insurance_detail.insurance5"
                >盗抢险</el-checkbox>
              </el-col>
              <!-- <el-col :span="12">
                <el-checkbox
                  false-label="2"
                  true-label="1"
                  v-model="form.tmp_insurance_detail.insurance6"
                >玻璃险</el-checkbox>
              </el-col> -->
              <el-col :span="12">
                <el-checkbox
                  false-label="2"
                  true-label="1"
                  v-model="form.tmp_insurance_detail.insurance7"
                >意外伤害险</el-checkbox>
              </el-col>
              <el-col :span="12">
                <el-checkbox
                  false-label="2"
                  true-label="1"
                  v-model="form.tmp_insurance_detail.insurance8"
                >座位险</el-checkbox>
              </el-col>
              <el-col :span="12">
                <el-checkbox
                  false-label="2"
                  true-label="1"
                  v-model="form.tmp_insurance_detail.insurance9"
                >随车行李险</el-checkbox>
              </el-col>
              <!-- <el-col :span="12">
                <el-checkbox
                  false-label="2"
                  true-label="1"
                  v-model="form.tmp_insurance_detail.insurance10"
                >自燃险</el-checkbox>
              </el-col> -->
            </el-row>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="main-content register-box" shadow="hover">
        <div class="jue-card-header">
          <div class="jue-card-title">其它信息</div>
        </div>

        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item :class="{'is-required': rules.book_date}" label="订车日期" prop="book_date">
              <el-date-picker
                placeholder="选择日期时间"
                type="date"
                v-model="form.book_date"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              :class="{'is-required': rules.deliver_date}"
              label="交车日期"
              prop="deliver_date"
            >
              <el-date-picker
                placeholder="选择日期时间"
                type="date"
                v-model="form.deliver_date"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item
              :class="{'is-required': rules.unbook_date}"
              label="退订日期"
              prop="unbook_date"
            >
              <el-date-picker
                placeholder="选择日期时间"
                type="date"
                v-model="form.unbook_date"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :class="{'is-required': rules.lose_date}" label="失控日期" prop="lose_date">
              <el-date-picker
                placeholder="选择日期时间"
                type="date"
                v-model="form.lose_date"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item :class="{'is-required': rules.fall_date}" label="战败日期" prop="fall_date">
              <el-date-picker
                placeholder="选择日期时间"
                type="date"
                v-model="form.fall_date"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="战败品牌">
              <el-input placeholder="请输入" suffix-icon="el-icon-edit" v-model="form.fall_brand"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              :class="{'is-required': rules.fall_reason}"
              label="战败原因"
              prop="fall_reason"
            >
              <el-select placeholder="请选择" v-model="form.fall_reason">
                <template v-for="(item, index) in $store.state.cfgList.fallReason">
                  <el-option
                    :key="index"
                    :label="item.f1"
                    :value="item.sys_id"
                    v-if="item.status == 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="战败车型">
              <el-input placeholder="请输入" suffix-icon="el-icon-edit" v-model="form.fall_model"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="战败说明">
              <el-input
                :rows="3"
                placeholder="请输入"
                type="textarea"
                v-model="form.fall_memo"
                value-format="yyyy-MM-dd"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>

      <!-- <el-card class="main-content register-box" shadow="hover">
        <div class="jue-card-header">
          <div class="jue-card-title">战败审核意见</div>
        </div>

        <el-row :gutter="20">
          <el-col :span="6" class="ins-amount sk">
            <el-form-item label="审核意见：">不同意</el-form-item>
          </el-col>
          <el-col :span="6" class="ins-amount sk">
            <el-form-item label="审核人：">向南</el-form-item>
          </el-col>
          <el-col :span="6" class="ins-amount sk">
            <el-form-item label="审核时间：">2020-01-02 12:09</el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24" class="ins-amount sk">
            <el-form-item label="审核备注：">无</el-form-item>
          </el-col>
        </el-row>
      </el-card>-->

      <el-card class="main-content register-box" shadow="hover" v-if="configList.length != 0">
        <div class="jue-card-header">
          <div class="jue-card-title">其它信息</div>
        </div>

        <el-row :gutter="20">
          <el-col :key="item.sys_id" :span="6" v-for="item in configList">
            <el-form-item :label="item.name">
              <el-input
                placeholder="请输入"
                suffix-icon="el-icon-edit"
                v-model="form.tmp_custom_data['field_'+item.sys_id]"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>

      <div class="form-footer">
        <el-button @click="submitForm('form')" type="primary">提交</el-button>
        <!-- <el-button
          @click="$router.push({name: 'customer-record', query: {client_id: $route.query.client_id}})"
        >取消</el-button>-->
        <el-button @click="$router.back()">取消</el-button>
      </div>
    </el-form>
    <client-detail ref="clientDetail"></client-detail>
  </div>
</template>
<script>
import { dateFormat, getTimeInterval } from '@/unit/unit.js'
import ClientDetail from '@/components/ClientDetail.vue'
export default {
  components: {
    ClientDetail,
  },
  data() {
    return {
      oss_token: null, //上传音频tokem
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      base: {
        inputVisible: false,
        inputValue: '',
      },
      resultList: [],
      configList: [],
      trackList: {},
      form: {
        client_id: '',
        expect_buy_date: '',
        media_amount: '',
        purchase_tax_amount: '',
        trace_date: dateFormat(),
        contend_brand: '',
        discount_amount: '',
        buy_parts_amount: '',
        client_level: '',
        contend_model: '',
        net_amount: '',
        buy_parts_items: '',
        next_trace_date: '',
        old_seller_uid: '',
        used_car_amount: '',
        donate_parts_items: '',
        // expect_pay_date: '',

        invite_income_date_begin_end: [],
        invite_income_date_begin: '',
        invite_income_date_end: '',
        call_audio_file: '',
        call_audio_list: [],
        insurance_tax_amount: '',
        trace_type: '',
        service_tax_amount: '',
        trace_status: '',
        fall_check_uid: '',
        tax_amount: '',
        credit_type: '',
        tmp_credit_detail: {
          loan: '',
          downPayment: '',
          monthlyPayment: '',
          interest: '',
          cost1: '',
          cost2: '',
          cost3: '',
        },
        credit_detail: '',
        tmp_insurance_detail: {
          three: '2',
          threePayment: '',
          insurance1: '2',
          insurance2: '2',
          insurance3: '2',
          insurance4: '2',
          insurance5: '2',
          insurance6: '2',
          insurance7: '2',
          insurance8: '2',
          insurance9: '2',
          insurance10: '2',
        },
        insurance_detail: '',
        trace_memo: '',
        book_date: null,
        deliver_date: null,
        unbook_date: null,
        lose_date: '',
        fall_date: '',

        fall_brand: '',
        fall_model: '',
        fall_reason: '',
        fall_memo: '',
        custom_data: '',
        tmp_custom_data: {},

        checkedIns: [],
        realname: ''
      },
      rules: {
        client_id: [{ required: true, message: '必需填写', trigger: 'change' }],
        media_amount: [
          { required: true, message: '必需填写', trigger: 'blur' },
        ],
        trace_date: [{ required: true, message: '必需填写', trigger: 'blur' }],
        client_level: [
          { required: true, message: '必需填写', trigger: 'change' },
        ],
        insurance_tax_amount: [
          { required: true, message: '必需填写', trigger: 'blur' },
        ],
        service_tax_amount: [
          { required: true, message: '必需填写', trigger: 'blur' },
        ],
        trace_type: [
          { required: true, message: '必需填写', trigger: 'change' },
        ],
        next_trace_date: [
          { required: true, message: '必需填写', trigger: 'change' },
        ],
        trace_status: [
          { required: true, message: '必需填写', trigger: 'change' },
        ],
      },
    }
  },
  mounted() {
    this.init()

    this.$axios.post('/audio/oss_token').then((res) => {
      if (res.data.code == 200) {
        this.oss_token = res.data.data
      }
    })
  },
  methods: {
    addDate(startDate, days) {
      startDate = new Date(startDate)
      startDate = +startDate + 1000 * 60 * 60 * 24 * days
      startDate = new Date(startDate)
      var mm =
        startDate.getMonth() + 1 < 10
          ? '0' + (startDate.getMonth() + 1)
          : startDate.getMonth() + 1
      var dd =
        startDate.getDate() < 10
          ? '0' + startDate.getDate()
          : startDate.getDate()

      var nextStartDate = startDate.getFullYear() + '-' + mm + '-' + dd
      return nextStartDate
    },
    setTraceStatus() {
      if (this.form.client_level != '') {
        if(!this.$route.query.trace_id){
          this.form.trace_status = ''
          this.form.book_date = ''
          this.form.deliver_date = ''
          this.form.unbook_date = ''
          this.form.lose_date = ''
          this.form.fall_date = ''
          this.form.trace_status = ''
        }
        
        let resultArr = []
        this.$store.state.cfgList.Result.map((item, index) => {
          if (item.f2 == this.form.client_level) {
            resultArr.push(item)
          }
        })

        if (resultArr.length == 0) {
          this.resultList = [
            {
              sys_id: 59,
              key: 'Result',
              f1: '继续跟踪',
              f2: '',
              f3: '',
              deleted: 2,
              status: 1,
              readonly: 1,
              order: 1,
              memo: '',
            },
          ]
        } else {
          this.resultList = resultArr
        }
      }
    },
    changeCustomerLevel() {
      //设置促进结果
      this.setTraceStatus()

      if (this.form.trace_date == '' || this.form.client_level == '') {
        return false
      }

      let client_level_arr = [185, 184, 186, 142, 22]

      if (
        client_level_arr.indexOf(this.form.client_level) >= 0 &&
        this.form.client_level == this.form.client_level_tmp
      ) {
        this.form.next_trace_date = this.form.trace_date
      } else {
        let days = this.$store.state.cfgData[this.form.client_level][1]
        this.form.next_trace_date = this.addDate(this.form.trace_date, days)
      }

      if (this.form.client_level == 22) {
        this.rules.fall_reason = [
          { required: true, message: '必需填写', trigger: 'change' },
        ]
        this.rules.fall_date = [
          { required: true, message: '必需填写', trigger: 'change' },
        ]
      } else {
        delete this.rules.fall_reason
        delete this.rules.fall_date
      }

      if (this.form.client_level == 185) {
        this.rules.book_date = [
          { required: true, message: '必需填写', trigger: 'change' },
        ]
      } else {
        delete this.rules.book_date
      }
      if (this.form.client_level == 184) {
        this.rules.deliver_date = [
          { required: true, message: '必需填写', trigger: 'change' },
        ]
      } else {
        delete this.rules.deliver_date
      }
      if (this.form.client_level == 186) {
        this.rules.unbook_date = [
          { required: true, message: '必需填写', trigger: 'change' },
        ]
      } else {
        delete this.rules.unbook_date
      }
      if (this.form.client_level == 142) {
        this.rules.lose_date = [
          { required: true, message: '必需填写', trigger: 'change' },
        ]
      } else {
        delete this.rules.lose_date
      }
    },
    changeClient() {
      this.$router.push({ query: { client_id: this.form.client_id } })
    },
    view(id) {
      this.$refs.clientDetail.view(id)
    },
    loadLastTrack() {
      this.$axios
        .post('/client/last_trace', {
          client_id: this.$route.query.client_id || 0,
          income_id: this.$route.query.income_id || 0,
        })
        .then((res) => {
          if (res.data.code == 200) {
            if (JSON.stringify(res.data.data) == '{}') {
              this.$message.error('没有上次跟踪记录')
            } else {
              delete res.data.data.trace_date
              delete res.data.data.audio_file_list
              this.setData(res)
            }
          }
        })
    },
    beforeUpload() {
      //获取音频token
      // this.$axios.post('/audio/oss_token').then(res => {
      //   if (res.data.code == 200) {
      //     this.oss_token = res.data.data
      //   }
      // })
    },
    delAudio(index) {
      this.form.call_audio_list.splice(index, 1)
    },
    upload(file) {
      let _this = this
      var formData = new FormData()

      formData.append(
        'key',
        _this.oss_token.dir + Date.parse(new Date()) + '/' + file.file.name
      )
      formData.append('policy', _this.oss_token.policy)
      formData.append('OSSAccessKeyId', _this.oss_token.access_key)
      formData.append('success_action_status', 200)
      formData.append('signature', _this.oss_token.signature)
      formData.append('callback', _this.oss_token.callback)
      formData.append('name', file.file.name)
      formData.append('file', file.file, file.file.name)

      this.$axios.post(_this.oss_token.host, formData).then((res) => {
        // this.$refs.upload.clearFiles()
        if (res.data.code == 200) {
          this.form.call_audio_list.push({
            name: res.data.data.file_name,
            url: res.data.data.file_url,
            file_id: res.data.data.file_id,
          })
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.call_audio_list.length > 0) {
            let arr = []
            this.form.call_audio_list.map((item) => {
              arr.push(item.file_id)
            })
            this.form.call_audio_file = arr.join('|')
          } else {
            this.form.call_audio_file = ''
          }

          this.form.custom_data = JSON.stringify(this.form.tmp_custom_data)
          this.form.insurance_detail = JSON.stringify(
            this.form.tmp_insurance_detail
          )
          this.form.credit_detail = JSON.stringify(this.form.tmp_credit_detail)

          if (this.form.fall_check_uid == '') {
            this.form.fall_check_uid = '0'
          }

          if (this.form.invite_income_date_begin == '') {
            delete this.form.invite_income_date_begin
          }

          if (this.form.invite_income_date_end == '') {
            delete this.form.invite_income_date_end
          }

          if (this.form.expect_buy_date == '') {
            delete this.form.expect_buy_date
          }

          if (this.form.media_amount == '') {
            delete this.form.media_amount
          }

          if (this.form.purchase_tax_amount == '') {
            delete this.form.purchase_tax_amount
          }

          if (this.form.discount_amount == '') {
            delete this.form.discount_amount
          }

          if (this.form.buy_parts_amount == '') {
            delete this.form.buy_parts_amount
          }

          if (this.form.net_amount == '') {
            delete this.form.net_amount
          }

          if (this.form.used_car_amount == '') {
            delete this.form.used_car_amount
          }

          if (this.form.insurance_tax_amount == '') {
            delete this.form.insurance_tax_amount
          }

          if (this.form.service_tax_amount == '') {
            delete this.form.service_tax_amount
          }

          if (this.form.tax_amount == '') {
            delete this.form.tax_amount
          }

          if (this.form.book_date == '') {
            delete this.form.book_date
          }

          if (this.form.deliver_date == '') {
            delete this.form.deliver_date
          }

          if (this.form.unbook_date == '') {
            delete this.form.unbook_date
          }
          if (this.form.lose_date == '') {
            delete this.form.lose_date
          }

          let url = '/trace/create'
          if (this.$route.query.client_id && this.$route.query.trace_id) {
            url = '/trace/edit'
          }

          this.$axios.post(url, this.form).then((res) => {
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
              //跳转至登记列表
              this.$router.push({
                name: 'customer-record',
                query: {
                  client_id: this.form.client_id,
                },
              })
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    },
    init() {
      //拉取所有自定义字段
      this.$axios
        .post('/cfgfield/lists', {
          key: 'kehugenzong',
        })
        .then((res) => {
          this.configList = res.data.data[0].list
        })
      this.getTrackList()
      this.$forceUpdate()

      //修改跟踪记录
      if (this.$route.query.client_id && this.$route.query.trace_id) {
        //获取trace_id的跟踪明细
        this.$axios
          .post('/trace/detail', {
            trace_id: this.$route.query.trace_id,
          })
          .then((res) => {
            this.setData(res)
          })
      } else {
        //自动载入上次跟踪数据
        // this.loadLastTrack()
      }
    },
    //载入上次跟踪记录或根据trace_id修改本次跟踪记录
    setData(res) {
      this.form.trace_id = res.data.data.trace_id
      this.form.expect_buy_date = res.data.data.expect_buy_date
      this.form.media_amount = res.data.data.media_amount
      this.form.purchase_tax_amount = res.data.data.purchase_tax_amount
      if (res.data.data.trace_date) {
        this.form.trace_date = res.data.data.trace_date
      }

      this.form.contend_brand = res.data.data.contend_brand
      this.form.discount_amount = res.data.data.discount_amount
      this.form.buy_parts_amount = res.data.data.buy_parts_amount
      this.form.client_level =
        res.data.data.client_level == ''
          ? ''
          : parseInt(res.data.data.client_level)

      this.form.client_level_tmp =
        res.data.data.client_level == ''
          ? ''
          : parseInt(res.data.data.client_level)

      this.form.contend_model = res.data.data.contend_model
      this.form.net_amount = res.data.data.net_amount
      this.form.buy_parts_items = res.data.data.buy_parts_items
      this.form.next_trace_date = res.data.data.next_trace_date
      this.form.old_seller_uid = res.data.data.old_seller_uid
      this.form.used_car_amount = res.data.data.used_car_amount
      this.form.donate_parts_items = res.data.data.donate_parts_items
      // this.form.expect_pay_date = res.data.data.expect_pay_date
      this.form.invite_income_date_begin =
        res.data.data.invite_income_date_begin
      this.form.invite_income_date_end = res.data.data.invite_income_date_end
      this.form.invite_income_date_begin_end = [
        res.data.data.invite_income_date_begin,
        res.data.data.invite_income_date_end,
      ]

      this.form.insurance_tax_amount = res.data.data.insurance_tax_amount
      this.form.trace_type =
        res.data.data.trace_type == '' ? '' : parseInt(res.data.data.trace_type)
      this.form.service_tax_amount = res.data.data.service_tax_amount
      // this.form.trace_status =
      //   res.data.data.trace_status == ''
      //     ? ''
      //     : parseInt(res.data.data.trace_status)
      this.form.tax_amount = res.data.data.tax_amount
      this.form.credit_type =
        res.data.data.credit_type == ''
          ? ''
          : parseInt(res.data.data.credit_type)
      this.form.trace_memo = res.data.data.trace_memo
      this.form.trace_status = parseInt(res.data.data.trace_status)
      this.form.tmp_credit_detail = JSON.parse(res.data.data.credit_detail)
      this.form.tmp_insurance_detail = JSON.parse(
        res.data.data.insurance_detail
      )

      this.form.book_date = res.data.data.book_date
      this.form.deliver_date = res.data.data.deliver_date
      this.form.unbook_date = res.data.data.unbook_date
      this.form.lose_date = res.data.data.lose_date
      this.form.fall_date = res.data.data.fall_date

      this.form.fall_brand = res.data.data.fall_brand
      this.form.fall_model = res.data.data.fall_model
      this.form.fall_reason =
        res.data.data.fall_reason == ''
          ? ''
          : parseInt(res.data.data.fall_reason)
      this.form.fall_memo = res.data.data.fall_memo
      if (res.data.data.audio_file_list) {
        this.form.call_audio_list = res.data.data.audio_file_list
      }

      this.form.call_audio_file = res.data.data.call_audio_file
      this.form.tmp_custom_data = eval('(' + res.data.data.custom_data + ')')
      this.changeCustomerLevel()
      this.$forceUpdate()
    },
    //获取所有跟踪客户列表
    getTrackList() {
      this.$axios.post('/client/detail', {
        client_id: Number(this.$route.query.client_id) 
      }).then((res) => {
        console.log(res.data.data)
        this.form.client_id = res.data.data.client_id
        this.form.realname = res.data.data.realname
      })
    },
    setIncomeDate() {
      if (this.form.invite_income_date_begin_end) {
        this.form.invite_income_date_begin = this.form.invite_income_date_begin_end[0]
        this.form.invite_income_date_end = this.form.invite_income_date_begin_end[1]
      } else {
        this.form.invite_income_date_begin = ''
        this.form.invite_income_date_end = ''
      }
    },
  },
  computed: {
    listenChange() {
      const { form } = this
      return { form }
    },
  },
  watch: {
    listenChange: {
      handler: function (val) {
        let media_amount = isNaN(parseFloat(val.form.media_amount))
          ? 0
          : parseFloat(val.form.media_amount)
        let discount_amount = isNaN(parseFloat(val.form.discount_amount))
          ? 0
          : parseFloat(val.form.discount_amount)

        this.form.net_amount =
          media_amount - discount_amount == 0
            ? ''
            : media_amount - discount_amount

        let purchase_tax_amount = isNaN(
          parseFloat(val.form.purchase_tax_amount)
        )
          ? 0
          : parseFloat(val.form.purchase_tax_amount)
        let buy_parts_amount = isNaN(parseFloat(val.form.buy_parts_amount))
          ? 0
          : parseFloat(val.form.buy_parts_amount)
        let used_car_amount = isNaN(parseFloat(val.form.used_car_amount))
          ? 0
          : parseFloat(val.form.used_car_amount)
        let insurance_tax_amount = isNaN(
          parseFloat(val.form.insurance_tax_amount)
        )
          ? 0
          : parseFloat(val.form.insurance_tax_amount)
        let service_tax_amount = isNaN(parseFloat(val.form.service_tax_amount))
          ? 0
          : parseFloat(val.form.service_tax_amount)

        let tax_amount =
          this.form.net_amount +
          purchase_tax_amount +
          buy_parts_amount -
          used_car_amount +
          insurance_tax_amount +
          service_tax_amount
        this.form.tax_amount = tax_amount == 0 ? '' : tax_amount
      },
      deep: true,
    },
  },
}
</script>
<style lang="less" scoped>
.form {
  /deep/ .el-form-item__label {
    padding-bottom: 0;
  }
  /deep/ .el-select,
  /deep/ .el-date-editor--timerange,
  /deep/ .el-date-editor {
    width: 100%;
  }
}
.form-footer {
  text-align: right;
  padding: 0 40px 0 0;
  el-button {
    margin-left: 20px;
  }
}

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.audio-box {
  /deep/ .el-icon-document::before {
    content: '\e7c0';
  }
}

.ins-amount,
.sk {
  /deep/ .el-form-item__label {
    padding-right: 12px;
    width: 85px;
  }
  /deep/ .el-form-item__content {
    display: inline-block;
  }
}

.sk {
  /deep/ .el-form-item {
    display: flex;
  }
}

.ins-items {
  display: flex;
  width: 250px;
  padding-bottom: 10px;
  /deep/ .el-checkbox {
    line-height: 32px;
  }
}

.insurance {
  /deep/ .el-col {
    padding: 8px 0;
  }
}

/deep/ .el-checkbox__label {
  width: 80px;
  font-weight: 400;
}

.audio-list {
  padding: 10px 0;
  .item {
    margin-bottom: 5px;
    height: 26px;
    display: flex;
    align-items: center;
    color: #606266;
    padding: 0 5px;
    border-radius: 3px;
    .el-icon-video-play {
      margin-right: 5px;
      cursor: pointer;
    }
    .name {
      flex: 1;
    }
    .el-icon-close {
      display: none;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      .el-icon-close {
        display: block;
        cursor: pointer;
      }
    }
  }
}
</style>